@import "./font-awesome.min.css";

@font-face{
  font-family:Roboto;
  font-style:normal;font-weight:300;
  src:url('/fonts/RobotoCondensed-Light.ttf');
}
@font-face{
  font-family:Roboto;
  font-style:normal;font-weight:700;
  src:url('/fonts/RobotoCondensed-Bold.ttf');
}

.pi {
  font-family: 'Font Awesome\ 5 Pro';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pi-calendar:before{content:"\f073"}
.pi-chevron-left:before{content:"\f053"}.pi-chevron-right:before{content:"\f054"}
.pi-exclamation-triangle:before{content:"\f071"}
.pi-times:before{content:"\f00d"}


*, .container {
  margin:0;
  padding:0;
}
html, body {
  height:100%;
}

html {
  overflow-x: hidden;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-weight: 700;
  font-family: Roboto;
}

.modal-body h5 {
  word-wrap: break-word;
}

body {
  font-family: Roboto;
}

.modal {
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
  overflow: auto;
  overflow: overlay; /* Allows for scrolling modal */
}

.modal-lg {
  width: 60%;
}
.modal-med {
  max-width: 800px;
}
.modal-sm {
  max-width: 300px;
  margin-top: 60px;
}

modal-overlay {
  z-index: 550 !important;
}

.modal-content {
  border: none;
}
.row img {
  width:100%;
}
.modal-pull-down {
  margin-top: 150px;
}

.modal-header {
  padding: 15px;
  border-bottom: none;
  background: #333;
  color: #fff;
}

.modal-header h3 {
  width:100%;
  font-size: 20px;
  margin:0;
  font-weight: 300;
  line-height:30px;
}

.modal-header h2 {
  margin-top: 4px;
  margin-bottom: 10.5px;
}


.modal-header i {
  padding-right: 10px;
  line-height:30px;
  font-size:20px;
  float:left;
}

.modal-body {
  position: relative;
  padding: 15px;
  float: left;
  width: 100%;
  background: #fff;
}

.modal-body#page-description {
  padding: 2px 20px;
}

.modal-body.alert-message {
  display: table;
  width: 100%;
  line-height: 11px;
}

.alert-icon, .alert-message i  {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}

.alert-icon {
  font-size: 20px;
  width: 30px;
  top: -4px;
}

.modal-footer {
  z-index: 9999;
  padding: 20px;
  text-align: right;
  float: left;
  width: 100%;
  background: #fff;
  border-top: none;
  /* box-shadow: 0 -1px 8px #4c4c4c; */
  border-top: 1px solid #ddd;
}


.app-footer {
  position: fixed;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  z-index: 400;
  /* height: 30px; */
  overflow: visible;
  font-size: 11px;
}
.app-footer p {
  font-size: 12px;
  font-weight: 700;
}

h1, .h1 {
  font-size: 32px;
  margin-right:10px;
}
h2, .h2 {
  font-size: 22px;
  float: left;
}
h3, .h3 {
  font-size: 20px;
  float: left;
  margin-top: 12px;
  margin-bottom: 10.5px;
}
p {
  width: 100%;
  float: left;
}
.uppercase {
  text-transform: uppercase;
}
.btn-custom {
  padding: 3px 12px;
}
.background-complete{
  background: #c0f5b2;
}

.TerminalServiceSidebarDiv {
  font-size: 10px;
  color: #d3d3d3;
  float: left;
  width: 100%;
  padding-right: 10px;
}

.TerminalServiceSidebarDiv div {
  padding:2px 2px;
}
.TerminalServiceSidebarDiv span{
  font-size: 19px;
  color: #d30400;
  float: right;
  line-height: 14px;
}
.sidebar-link-btn {
  cursor: pointer;
}
.sidebar-link-btn:hover {
  background: #000;
}


.sidebar-section {
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  padding: 6px 0px 5px 16px;
  display: block;
  border-top: solid 1px #404a4e;
  color: #fff;
}


.sidebar-section span {
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
}
.inline-icon-small {
  font-size: 18px;
  display: inline-block;
  float: left;
  padding-right: 10px;
  line-height: 22px;
  min-height: 10px;
  cursor: pointer;
}

.logo {
  max-width: 100%;
}

.terminal-image {
  max-width: 670px;
  width: 100%;
}

.dark-background {
  background: #313131;
}

.form-control[disabled], fieldset[disabled] .form-control,
p-calendar input[disabled], p-calendar button[disabled] {
  cursor: not-allowed;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control,
p-calendar input[disabled] {
  background-color: #e6e6e6;
  opacity: 1;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.visible {
  pointer-events: none;
  opacity: 0;
}
.notification-counter {
  cursor: pointer;
}


label {
  margin-bottom: 1px;
  font-size: 11px;
}
.form-group.required label:after {
  content:"*";
  color:red;
}
.form-group {
  margin: 0 0 15px 0 !important;
}
.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.glyphicon-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

ul .navbar-right li:hover .dropdown-menu {
  display: block !important;
}

.navbar-nav li:hover ul.dropdown-menu {
  display: inline;
}
.caret:after {
  content: '';
  position: absolute;
  top: 25%;
  left: 13px;
  border-top: 11px solid #fff;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
}

.caret:hover:before {
  border-top-color: #222;
}

.language-flags {
  float: right;
  padding: 4px 3px;
}


.language-flags:active img, .language-flags.active img, .language-flags:hover img  {
  opacity: 0.5;
}

.language-flags img{
  width:42px;
}
/**
 * Progress bar animations
 */
@-webkit-keyframes query {
  0% {
    opacity: 1;
    -webkit-transform: translateX(35%) scale(0.3, 1);
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-50%) scale(0, 1);
    transform: translateX(-50%) scale(0, 1); } }

@keyframes query {
  0% {
    opacity: 1;
    -webkit-transform: translateX(35%) scale(0.3, 1);
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-50%) scale(0, 1);
    transform: translateX(-50%) scale(0, 1); } }


.BackgroundImage{
  /*background: url(./images/silo_01.jpg);
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 100%;
  bottom: 0;
  background-position: bottom;
  z-index: -10;
  background-repeat: no-repeat;
*/
}


/**
* MERGE WITH WEB
**/

.no-margin{
  margin-top: 0px;
  margin-bottom:0px;
}

/**
** Custom CSS which overrides the bootstrap style
**/

hr {
  margin-top: 11px;
  margin-bottom: 11px;
}

.error {
  color: #ff4309;
  font-size: 14px;
}

.form-control {
  height: 27px;
  padding: 2px 4px;
  font-size: 12px;
}

.radio, .checkbox {
  left: 15px;
}

.btn-group {
  margin-bottom: 10px;
}

/* So that SORTING works */
th span {
  pointer-events: none;
}

.page-header {
  padding-bottom: 0;
  margin: 0 0 10px;
  position: relative;
  width: 100%;
  float: left;
  border-bottom: 1px solid #e6e6e6;
  padding-right: 5px;
 }

.page-header h1 {
  float: left;
}

#page-description {
  margin: 0 0 10px 0;
  font-size: 14px;
  padding-left: 0;
  display: inline-block;
  width: 100%;
}

#page-description ul {
  margin-top: 0;
  text-indent: 1px;
  margin-left: 15px;
}

.hide-description-btn {
  margin-top: 21px;
  margin-bottom: 10.5px;
  float:right;
}

.input-group {
  width: 100%;
}

.pull-down {
  margin-top: 22px;
}

.panel-heading {
  padding: 4px 8px;
  font-size: 13px;
}

.panel-body {
  padding: 6px;
  padding-bottom: 8px;
}

.error-standard-text {
  color: #000;
  font-weight: normal;
}

.panel {
  width: 100%;
  margin-bottom: 17px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  float: left;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padded {
  margin-left:10px;
  margin-right:10px;
  padding:5px;
}

.login-panel {
  background: #0a5498;
  padding-left:20px;
  padding-right:20px;
  color: #fff;
}

.login-panel .col-sm-12 {
  padding:0;
}

.quote-panel {
  background: #e8e8e8;
  padding-left:20px;
  padding-right:20px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: 5px;
  padding-right: 5px;
}

/* Apply to body when modal is opened - then remove when modal is closed */
.no-scroll {
  overflow: hidden;
}

thead {
  background: #2f71a8;
  color: #fff;
}

.btn-selected {
  background: #2f71a8;
  color: #fff !important;
}

.btn-secondary{
  padding: 7px 10px;
  font-size: 13px;
}

.btn {
  padding: 6px 17px;
}

.btn-action{
  background: #eb8d36;
  border: none;
  color:#fff;
}

.btn-action:hover{
  background: #eb4f2d;
  border: none;
}

.btn-action.active.focus, .btn-action.active:focus, .btn-action.active:hover, .btn-action:active.focus,
.btn-action:active:focus, .btn-action:active:hover, .open>.dropdown-toggle.btn-action.focus,
.open>.dropdown-toggle.btn-action:focus, .open>.dropdown-toggle.btn-action:hover {
  color: #fff;
  background-color: #eb4f2d;
  border-color: #eb8d36;
}

.btn-action.active, .btn-action:active, .btn-action:hover, .open>.dropdown-toggle.btn-action {
  background-color: #eb4f2d;
  border-color: #eb8d36;
}

.pull-space {
  margin-left:10px;
}

.btn-secondary {
  color: #000;
}

.btn-secondary:hover {
  background: #2f71a8;
}

.red-background {
  background: #f20008 !important;
  color: #fff;

}
/**
** CSS for aligning primeNG components with Bootstrap
**/

.ui-datepicker {
    padding: 0px;
    z-index: 450 !important;
    width: auto;
position:absolute;
}
.ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover, a.ui-state-default.ui-state-active {
    background: #daebff;
    color: #212121;
}
.ui-datepicker select.ui-datepicker-year {
    width: 35%;
    margin-left: 10px;
    font-weight: 300;
    color: #1c3160 !important;
}
dp-dateedit .ui-datepicker-calendar .ui-datepicker-other-month.ui-state-disabled .ui-state-default {
    background: #ececec;
}

dp-dateedit .ui-datepicker-calendar .ui-datepicker-other-month.ui-state-disabled .ui-state-default:hover {
    cursor:pointer !important;
    background: #d8d8d8;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-highlight {
    background-color: #d6d6d6;
    color: #212121;
}
.ui-widget-header .ui-state-default, .ui-widget-content .ui-state-default, .ui-state-default {
    border: 1px solid #d6d6d6;
    background: #ffffff;
    color: #555555;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td {
    border-bottom: 1px solid rgba(213, 213, 213, 0.5);
    padding: 0;
}
.ui-widget-content {
    border: 1px solid #D5D5D5;
    background: #ffffff;
    color: #222222;
}
.ui-datepicker.ui-datepicker-inline {
    display: block;
    position: static
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: .125em;
    width: 1.8em;
    height: 1.8em
}

.ui-datepicker .ui-datepicker-prev {
    left: .125em
}

.ui-datepicker .ui-datepicker-next {
    right: .125em
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -.5em
}

.ui-datepicker .ui-datepicker-prev span {
    margin-left: -.25em
}

.ui-datepicker .ui-datepicker-next span {
    margin-left: -.125em
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    font-weight: 700;
    color: #1c3160;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: .125em 0
}

.ui-datepicker select.ui-datepicker-month {
    width: 55%
}


.ui-datepicker select.ui-datepicker-month {
    margin-right: .25em
}

.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em
}

.ui-datepicker td {
    border: 0;
    padding: .125em
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none
}

.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0
}

.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left
}

.ui-datepicker.ui-datepicker-multi {
    width: auto
}

.ui-datepicker-multi .ui-datepicker-group {
    float: left
}

.ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto .4em
}

.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%
}

.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%
}

.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left
}

.ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0
}

.ui-datepicker.ui-widget .ui-datepicker-calendar td a {
    border: 0;
    text-align: center;
    padding: 2px 11px;
    border-left: 1px solid #eaeaea;
}

.ui-datepicker th {
    padding: 2px 9px;
    text-align: center;
    font-weight: 300;
    border-left: 1px solid #7fc0e2;
}

.ui-overlaypanel {
  padding:0;
  margin:0;
  position:absolute;
}
.ui-overlaypanel-content {
  padding: 0;
  float: left;
  width: auto;
}
.ui-overlaypanel.ui-widget-content {
  border: none;
}
.ui-overlaypanel-close {
  position:absolute;
  top:-.5em;right:-.5em;
  -moz-border-radius:100%;
  -webkit-border-radius:100%;
  border-radius:100%;
}
.ui-overlaypanel .modal-header {
  font-size: 14px;
}

.ui-overlaypanel .modal-header, .ui-overlaypanel .modal-body, .ui-overlaypanel .modal-footer {
  padding: 4px 15px;
}
.ui-overlaypanel .modal-footer {
  padding: 4px 5px;
}
.ui-overlaypanel .modal-footer > .btn {
  padding: 1px 10px;
  margin: 0;
  font-size: 13px;
}

.ui-calendar.ui-calendar-w-btn input {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0
}

.ui-timepicker {
  text-align: center;
  padding: .5em 0
}

.ui-timepicker>div {
  display: inline-block;
  margin-left: .5em;
  min-width: 1.5em
}

.ui-timepicker>.ui-minute-picker,
.ui-timepicker>.ui-second-picker {
  margin-left: 0
}

.ui-timepicker>.ui-separator {
  margin-left: 0;
  min-width: .75em
}

.ui-timepicker>.ui-separator a {
  visibility: hidden
}

.ui-timepicker>div a {
  display: block;
  opacity: .7;
  filter: Alpha(Opacity=70)
}

.ui-timepicker>div a:hover {
  display: block;
  opacity: 1;
  filter: Alpha(Opacity=100)
}
.ui-calendar button {
  position: absolute;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 2em;
  border-left: 0 none;
}
.ui-button-icon-only {
  width: 2em;
}
.ui-button {
  display: inline-block;
  position: relative;
  padding: 0;
  margin-right: .1em;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
}
.ui-button-text {
  display: none;
}

.ui-button-icon-only .fa {
  top: 50%;
  left: 50%;
  margin-top: -.5em;
  margin-left: -.6em;
}
.ui-button-icon-only .fa, .ui-button-text-icon-left .fa, .ui-button-text-icon-right .fa {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
}
.ui-button-icon-left {
  left: .5em;
}
.ui-calendar button {
  position: absolute;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 2em;
  border-left: 0 none;
}
.ui-state-disabled a {
  cursor: default !important;
}

.ui-widget-header .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-state-highlight {
  border-color: #156090;
  background: #186ba0;
  color: #FFFFFF;
}
.ui-button-icon-only .fa, .ui-button-text-icon-left .fa, .ui-button-text-icon-right .fa {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
}
.ui-widget-content {
  border: 1px solid #D5D5D5;
  background: #ffffff;
  color: #222222;
}
.ui-widget-header {
  background: #f6f7f9 0 0 repeat-x;
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f7f9), color-stop(100%, #ebedf0));
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}

.ui-shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
  -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
}

.ui-inputtext, .form-control, .input-group-addon {
  height: 23px;
  font-size: 11px !important;
  padding: 0 3px;
  margin: 0;
  outline: medium none;
  font-weight: normal;
  border-radius: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}

/*********************************************
* CSS overrides PRIMENG ui-inputtext
**********************************************/

.ui-calendar {
  width: 100%;
}
.ui-calendar.ui-calendar-w-btn input {
  width: 100%;
}
.ui-button, button.ui-button.ui-state-default, .ui-button.ui-state-default {
  border: none;
  color: #FFFFFF;
  right: 0;
  margin-right: 0;
  z-index: 450;
  background: #2f71a8;
  border-radius: 0;
}

.ui-button {
  display: inline-block;
  position: relative;
  padding: 0;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
}


/*********************************************
* CSS overrides PRIMENG ui-tooltip
**********************************************/
.ui-tooltip {
  position:absolute;
  display:none;
  padding: .25em .5em;
  z-index: 9999 !important;
}

.ui-tooltip.ui-tooltip-right,
.ui-tooltip.ui-tooltip-left {
  padding: 0 .25em;
}

.ui-tooltip.ui-tooltip-top,
.ui-tooltip.ui-tooltip-bottom {
  padding:.25em 0;
}

.ui-tooltip .ui-tooltip-text {
  padding: .125em .5em;
  background-color: rgb(76, 76, 76);
  color: #ffffff;
  white-space: nowrap;
}

.ui-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.ui-tooltip-right .ui-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -.25em;
  border-width: .25em .25em .25em 0;
  border-right-color: rgb(76, 76, 76);
}

.ui-tooltip-left .ui-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -.25em;
  border-width: .25em 0 .25em .25em;
  border-left-color: rgb(76, 76, 76);
}

.ui-tooltip.ui-tooltip-top {
  padding: .25em 0;
}

.ui-tooltip-top .ui-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -.25em;
  border-width: .25em .25em 0;
  border-top-color: rgb(76, 76, 76);
}

.ui-tooltip-bottom .ui-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -.25em;
  border-width: 0 .25em .25em;
  border-bottom-color: rgb(76, 76, 76);
}


.form-group {
  margin: 0px 0px 15px 0px !important;
}
.form-group.required label:after {
  content:"*";
  color:red;
}
.calendar-wrapper{
  padding-left: 5px;
  padding-right: 5px;
}

select option:first-child {
  color: #999;
}


thead tr th.ui-state-default {
  background: #2f71a8 !important;
  border-color: #dddddd !important;
}

tfoot tr th.ui-state-default {
  background-color: #9954bb !important;
  border-color: #dddddd !important;
}
tfoot tr {
  background-color: #9954bb !important;
}
.ui-column-title, .ui-column-footer {
  color: #ffffff !important;
  font-weight: normal;
}
.ui-widget-header a {
  color: #222222;
  background: #fff;
}
.ui-datatable .ui-sortable-column-icon {
  color: #fff;
}
.ui-button, .ui-button span, button.ui-button.ui-state-default span , .ui-button.ui-state-default span  {
  font-weight: normal !important;
}

.ui-accordion {
  width: 100%;
}

.ui-accordion .ui-accordion-header {
  cursor: pointer;
  position: relative;
  margin-top: 1px;
  zoom: 1;
}

.ui-accordion .ui-accordion-header a {
  display: block;
  padding: .5em .5em .5em 2em;
}

.ui-accordion .ui-accordion-header .fa {
  position: absolute;
  left: .5em;
  top: 50%;
  margin-top: -.5em;
}

.ui-accordion .ui-accordion-content {
  padding: 1em;
  border-top: 0;
  overflow: visible;
  zoom: 1;
}

.ui-accordion .ui-accordion-header.ui-state-disabled,
.ui-accordion .ui-accordion-header.ui-state-disabled a {
  cursor: default;
}

.ui-accordion-content-wrapper-overflown {
  overflow: hidden;
}

.ui-accordion .ui-accordion-header.ui-state-active {
  background: #d6d6d6 !important;
}
.ui-accordion .ui-accordion-header {
  background: #f1f1f1 !important;
}
.ui-accordion .ui-accordion-header a {
  font-weight: normal !important;
}
.ui-accordion .ui-accordion-content.ui-widget-content {
  border: 0 none;
  border-radius: 0px 0px 3px 3px;
  border: 1px solid #d4d4d4 !important;
}


/* Navbarchanges for userinfo */
.navbar {
  margin-bottom: 0px;
  min-height: 43px;
}

.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  padding-left: 7px;
  padding-right: 7px;
  color: #fff;
}

.navbar-nav>li>a:hover, .navbar-nav>li>a:focus, .navbar-nav>.active>a:focus, .navbar-nav>.active>a, .navbar-nav>.active>a:hover, .navbar-nav>.active>a:focus {
  color: #fff;
  background-color: #1967be;
}

.navbar-toggle.navbar-left {
  float: left;
  margin-left: 10px;
}
.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 12px;
  padding: 9px 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggle .icon-bar {
  background: #fff!important;
}
.navbar-toggle:hover {
  background: rgba(0,0,0,.2);
}

.navbar-user-info {
  float: right;
  color: #fff;
  font-size: 11px;
  position: relative;
  width: 100%;
  padding: 2px 18px 1px 0px;
  text-align: right;
  bottom: -1px;
  left: 15px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00003f+0,10539e+100&0+1,1+100 */
  background: -moz-linear-gradient(left,  rgba(0,0,63,0) 0%, rgba(0,1,64,0) 1%, rgba(16,83,158,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(0,0,63,0) 0%,rgba(0,1,64,0) 1%,rgba(16,83,158,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(0,0,63,0) 0%,rgba(0,1,64,0) 1%,rgba(16,83,158,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0000003f', endColorstr='#10539e',GradientType=1 ); /* IE6-9 */
}

.modal {
  background: rgba(0, 0, 0, 0.3);
  z-index: 9995;
  overflow: auto;
  -webkit-transition: opacity .55s linear;
  transition: opacity .55s linear;
}

container-enquiry .modal{
  z-index:999;
}
/* 100% wide on all screens */

.container {
  position: relative; /* so we can insert absolute sidebar within */
  width: 100% !important;
  padding-right: 15px;
}

.col-logo {
  padding-bottom: 2px;
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 20px;
  width: 150px;
  float: left;
  position: relative;
  height: 100%;
  background: none;
}

.col-menu {
  color: #fff;
  width: 100%;
  margin-left: -185px;
  padding-left: 185px;
  float: right;
}

.container-header {
  padding-left:15px;
}

.container-navbar {
  padding-right:15px;
}
/* 10-02-2017 - App Design Changes such as fixed header side bar etc*/
/* Table Styling */

.table {
  table-layout: auto;
  display: table; /* required for table-layout to be used (not normally necessary; included for completeness) */
  /*table-layout:fixed;*/ /* this keeps your columns with fixed with exactly the right width */
  width: 100%; /*
  /* table-layout: fixed; */
  margin-bottom: 5px;
}

.table-link {
  font-weight: normal;
  color: #2780e3;
  cursor: pointer;
}

.details-link {
  font-size: 13px;
  font-weight: bold;
  color: #2780e3;
  cursor: pointer;
}

.info-table {
  overflow: hidden;
  border: 1px solid #eff5e8;
  margin-bottom:10px;
}

/* TABLE STYLING */
.info-table>.row:nth-of-type(odd) {
  background: none;
  position: static;
}

.info-table>.row:nth-of-type(even) {
  background-color: #eff5e8;
  position: static;
}

table {
  font-size: 13px;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
  padding: 5px;
  font-size:11px;
  vertical-align: middle;
}

.table-bordered, .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
  border: none;
}

.table-bordered>tbody>tr>td {
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #eaeaea;
}

.table-bordered>thead>tr>th {
  border-left: 1px solid #719dc1;
}

.table>tbody>tr.info>td, .table>tbody>tr.info>th, .table>tbody>tr>td.info, .table>tbody>tr>th.info, .table>tfoot>tr.info>td, .table>tfoot>tr.info>th, .table>tfoot>tr>td.info, .table>tfoot>tr>th.info, .table>thead>tr.info>td, .table>thead>tr.info>th, .table>thead>tr>td.info, .table>thead>tr>th.info {
  background-color: #828282;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background: none;
}

.table-striped>tbody>tr:nth-of-type(odd) td {
  background-color: #f9f9f9;
  position: static;
}

.table-hover > tbody > tr:hover, .table-striped>tbody>tr:hover td {
  background-color: rgba(183, 245, 160, 0.3) !important;
  color: #000 !important;
}

/* REVIEW FINISH  styling contianer enquiry */
dp-label.background:nth-of-type(even) div {
  background-color: #eff5e8;
}

.table-dashboard {
  width:100%;
}

.table-dashboard>thead>tr>th, .table-dashboard>tbody>tr>th, .table-dashboard>tfoot>tr>th, .table-dashboard>thead>tr>td, .table-dashboard>tbody>tr>td, .table-dashboard>tfoot>tr>td {
  padding: 5px;
  vertical-align: middle;
}

.table-dashboard>thead>tr>td, .table-dashboard>tbody>tr>td, .table-dashboard>tfoot>tr>td {
  font-size:17px;
  text-align: center;
}

.table-dashboard tr th {
  font-size: 18px;
  background: #2f71a8;
  color: #fff;
}

.table-dashboard tr thead th {
  background: #00003f;
}

.table-dashboard thead th {
  font-size: 23px;
  background: #00003f;
  color: #fff;
}

.table-dashboard>tbody>tr:nth-of-type(odd) td {
  background-color: #f9f9f9;
  position: static;
}

.table-dashboard tfoot tr {
  background: #00003f !important;
}

.table-dashboard tfoot tr td{
  text-align: left;
  font-size: 23px;
}

p {
  margin: 0 0 7px;
  font-style: normal;
}

p.secondary {
  font-style: italic;
}

.app-sidebar p {
  width: 100% !important;
  float: left !important;
  padding: 10px !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: roboto condensed;
}

.app-sidebar .row.padded {
  padding-left: 0 !important;
  padding-right: 0 !important;

}

.row1 {
  /* display: flex; */
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

app-driver-appointment-summary .page-header h1 {
  font-size: 20px;
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 3px;
}

.table-icon-link {
  color: #2780e3;
  cursor: pointer;
}

td button.btn {
  padding: 0px 8px;
  width: 100%;
}

.text-success, .text-success:hover {
  color: #3fb618 !important;
}

.text-danger, .text-danger:hover {
  color: #ff0003 !important;
}

.text-custom, .text-custom:hover {
  color: #818181 !important;
}

.text-grey, .text-disabled {
  color: #d6d6d6 !important;
}

.text-grey:hover {
  color: #3fb618 !important;
}

.text-center {
  text-align: center !important;
}

.image {
  width:100%;
  padding: 20px 10px 10px 5px;
}

.container-margin {
  margin-top: 60px;
  padding-right: 15px;
  padding-left: 15px!important;
  position: fixed;
  bottom: 46px;
  overflow-x: auto;
  height: calc(100% - 105px);
}

.form-invalid {
  border-color: #e61e00 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(230, 30, 0, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(230, 30, 0, 0.6) !important;
}

.form-warning {
  border-color: #e6a500 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(230, 165, 0, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(230, 165, 0, 0.6) !important;
}

.form-valid {
  border-color: #03b300 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(3, 179, 0, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(3, 179, 0, 0.6) !important;
}

.app-header {
  background-color: #00003f;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 400;
  height: 60px;
  overflow: visible; /* making sure big logos dont enlarge header */
}

.app-header-big {
  height: auto !important;
  position:relative !important;
  margin-bottom: -55px;
}

.navbar-inverse {
  background-color: #00003f;
  border-color: #00003f;
}

.wrapper {
  margin-left: 200px;
}

.pagination > li:not(.disabled):hover a, .pagination li.active a {
  background: #2780e3;
  color: #fff;
}

.pagination > li.disabled i {
  color: #efefef;
}

.app-sidebar {
  position: fixed; /*absolute*/
  left: 200px;
  width: 200px;
  top: 0;
  margin-top: 60px;
  background-color: #313b3f;
  border-right: solid 1px #151d21;
  overflow: hidden;
  height: 100%;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  display: table-cell;
  z-index: 400;
}

.app-sidebar .row {
  margin: 0px !important;
}

app-sidebar a {
    display: block;
    font-size: 14px;
    font-weight: 700;
    padding: 9px 0px 7px 9px;
    cursor: pointer;
    border-top: solid 1px #404a4e;
    box-sizing: border-box;
    color: #fff !important;
    /* font-family: Roboto; */
}

.app-sidebar .row > a, .app-sidebar .row > div a {
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
    padding: 6px 0px 5px 16px;
}

.app-sidebar .row > a:last-of-type {
  border-bottom: 1px solid #404a4e;
}

.secondary-title {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
}

.app-sidebar .row > a:hover,
.app-sidebar .row > a.active,
.app-sidebar .row > div a:hover,
.app-sidebar .row > div a.active,
.app-sidebar > a:hover,
.app-sidebar > a.active {
    background: #272e31;
}

.app-sidebar .row > h4 {
  padding-left:10px;
  font-size: 14px;
  font-weight: bolder;
  color: #fff;
}

.app-sidebar .col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.app-sidebar.left {
  left: 200px;
}

.remove-vessel-btn {
  color: #fff;
  right: 0px;
  top: 1px;
  text-align: center;
  position: absolute;
  padding: 17px 14px 0px 9px;
  bottom: 0px;
  cursor:pointer;
}

.remove-vessel-btn:hover {
  background: #8e8e8e;
}
/**
** CSS for Calendar Button
**/

.ui-calendar {
  position: relative;
  display: inline-block
}

.ui-calendar button {
  position: absolute;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 2em;
  border-left: 0 none
}

.ui-fluid .ui-calendar {
  width: 100%
}

.ui-fluid .ui-calendar button {
  width: 2em
}

.ui-fluid .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  width: calc(100% - 2em)
}
.ui-button, button.ui-button.ui-state-default, .ui-button.ui-state-default {
  border: none;
  color: #FFFFFF;
  right: 0px;
  margin-right: 0px;
  z-index: 450;
  border: none;
  background: #2f71a8;
  border-radius: 0px;
}

.ui-button {
  display: inline-block;
  position: relative;
  padding: 0;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
}

.btn-sm, .btn-group-sm>.btn {
  font-size: 15px !important;
  padding: 0px 17px;
}
.btn-group {
  margin-bottom:0px;
  border:none;
}
.btn-group-justified a {
  background: #a2a2a2;
  color: #fff;
}
.btn-group-justified .btn-selected, .btn-group-justified a:hover {
  background: #2f71a8;
}
.login-btn {
  font-size: 19px !important;
  padding: 0px 18px;
  margin-bottom:5px;
}

fieldset {
  margin: 0;
  min-width: 0;
  width: 100%;
}

/**
** CSS for smartphone screens
**/

@media screen and (max-width: 768px) {
  .navbar-toggle {
    display: block !important;
  }

 .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
   padding-left: 0px;
   padding-right: 0px;
 }

 .container-margin{
   padding-left: 0px !important;
   padding-right: 0px !important;
 }

 .navbar-main{
   display:none;
 }

 .app-header {
   height: 44px;
 }

 .col-sm-6{
   text-align: center;
 }

 .ui-datepicker {
   width: 100%;
 }

 .logo {
   width:200px;
 }

 .page-header {
   margin-top: 5px;
 }

 .terminal-image {
   display:none;
 }

 .modal-footer {
   padding: 0px !important;
 }

  .modal {
    /*background: #FFF;*/
  }

 /* CSS to make smaller screens seem more like a phone app */
 .modal-content {
   height: 100%;
   border: none;
 }

 .modal-dialog {
    height: 100%;
    margin: 0 !important;
}

 .modal-footer .btn {
   margin: 0;
   width:50%;
   height:50px;
 }

 .modal-med {
   max-width: 800px;
 }

 .modal-footer .btn+.btn {
   margin-left: 0px !important;
 }

 .col-menu {
   padding: 0px;
 }
 .col-logo {
   left: calc(50% - 50px);
 }

 /* Animation for nav menu */
 .slide-in {
   animation: slide-in 0.5s forwards;
   -webkit-animation: slide-in 0.5s forwards;
 }
 .slide-out {
   animation: slide-out 0.5s forwards;
   -webkit-animation: slide-out 0.5s forwards;
 }
 @keyframes slide-in {
   100% { transform: translateX(0%); }
 }
 @-webkit-keyframes slide-in {
   100% { -webkit-transform: translateX(0%); }
 }
 @keyframes slide-out {
   0% { transform: translateX(0%); }
   100% { transform: translateX(-100%); }
 }
 @-webkit-keyframes slide-out {
   0% { -webkit-transform: translateX(0%); }
   100% { -webkit-transform: translateX(-100%); }
 }

 .app-sidebar {
   /*position: absolute; */
   left: 0;
   width: 100%;
   height: 100%;
   overflow-y: auto;
   z-index: 998;
   margin-top: 44px;
 }

 .app-sidebar.left {
   left:0;
 }

 .wrapper {
   width: auto;
   float: left;
   margin-left: 0;
   width: 100%;
   padding: 0px 5px;
 }

 .navbar-user-info {
   display:none;
 }

 .modal-lg {
   width: 100%;
 }
 .modal-sm {
   max-width: 100%;
   width: 100% !important;
   height: 100% !important;
 }

 .container-margin {
   margin-top: 50px;
   height: calc(100% - 90px);
 }
 .modal-footer {
   /* position: fixed; */
   background: #333;
   bottom: 0;
 }

}




/* bradigson  10-06-2023*/


.modal-footer
{
  display: flex;
  justify-content: center;
  
}
.modal-footer > button
{
  background: red;
  color:#eee;
}



@media only screen and (min-width:150px) and (max-width:760px)
{
   .modal-footer
  {
    display: flex;
    justify-content: center;
    margin-top: 15rem;
    background: none;
    
  }
  .modal-footer > button
  {
    background: red;
    color:#eee;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {

  .app-header {
    height: 100px;
  }
  .app-sidebar {
    margin-top: 100px;
  }
  .container-margin {
    height: calc(100% - 140px);
  }

}
@media screen and (min-width: 768px) and (max-width: 1300px) {

  /*
   .col-menu {
     margin-left: -150px;
     padding-left: 162px;
   }
   */
 /*  .col-menu {
     margin-left: 0px;
     padding-left: 0px;
   }*/


  .navbar-nav>li>a {
    font-size: 14px;
    font-weight: 400;
  }

  
  .modal-lg {
    width: 90%;
  }

  .app-sidebar .row > a, .app-sidebar .row > div a {
    font-size: 17px;
    padding: 14px 0px 14px 16px;
  }
  .app-sidebar .row > h4 {
    padding-left: 10px;
    font-size: 23px;
  }

}

@media screen and (max-width: 1100px) {
  table {
    font-size: 9px;
  }
}

@media screen and (max-width: 768px) {
  .modal-lg, .modal-med {
    width: 100% !important;
  }
  table {
    font-size: 12px;
  }
}

.pull-right {
  float: right!important;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 12px;
  padding: 9px 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
/* .navbar-toggle .icon-bar {
  background: #fff!important;
} */
.navbar-toggle:hover {
  background: rgba(0,0,0,.2);
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.page-header {
  padding-bottom: 0;
  margin: 0 0 10px;
  position: relative;
  width: 100%;
  float: left;
  border-bottom: 1px solid #e6e6e6;
  padding-right: 5px;
}